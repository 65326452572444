import React, {ReactNode, useMemo} from 'react'
import {Block, BlockSlider, BlockType, KirbyCarousel, KirbyImage} from "@gk-lab/kirbyreact";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getResponsiveValues} from "../utils/utils";

export interface Props {
  data: BlockSlider,
  hideNavigation?:boolean,
}

export default function IDMImageSlider (props:Props) {

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  // const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  function getBackground (children:JSX.Element):JSX.Element {
    return (
      <Box
        sx={{
          marginTop: 0,
        }}
      >
        {children}
      </Box>
    );
  }

  function getWrapper (children:JSX.Element):JSX.Element {
    return getBackground(
      <Box className={"image-slider"}>
        {children}
      </Box>
    );
  }

  const slides:ReactNode = useMemo(() => {
    if(props.data.slides) {
      return props.data.slides.map((slide:Block, idx:number) => {
        if(slide.type === BlockType.IMAGE) {
          return (
            <Box
              key={`image-${idx.toString()}`}
              sx={{
                height: props.data.height ? getResponsiveValues(props.data.height) :  {xs: "50vh"},
                minHeight: props.data.minheight ? getResponsiveValues(props.data.minheight) :  "unset",
              }}
            >
              <KirbyImage data={slide} />
            </Box>
          );
        }
        return null;
      });
    }
    return [];
    /* eslint-disable-next-line */
  },[props.data, isSm, isMd, isLg]);

  return getWrapper(
    <KirbyCarousel
      buttonSx={{
        background: (theme) => theme.palette.common.white,
        ":hover, &.active, &.focus ": {
          background: (theme) => theme.palette.common.white,
        },
      }}
      indicatorPos={"right"}
      navigationPos={"spread"}
      duration={props.data.duration}
      hideNavigation={props.hideNavigation || !isMd}
    >
      {slides}
    </KirbyCarousel>
  );
}
