import React, {useMemo} from 'react'
import {Block, BlockSlider, KirbyCarousel} from "@gk-lab/kirbyreact";
import {Box, Stack} from "@mui/material";
import IDMCard from "./IDMCard";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IDMHeadline from "./IDMHeadline";
import IDMText from "./IDMText";
import {getBlockElement} from "../utils/sectionUtils";

export interface Props {
  data: BlockSlider,
}

export default function IDMCardSlider (props:Props) {

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  function getBackground (children:JSX.Element):JSX.Element {
    if (props.data.image) {
      return (
        <Box
          className={"card-slider"}
          sx={{
            backgroundImage: props.data.image ? `url(${props.data.image.url})` : "unset",
            backgroundSize: "cover",
            backgroundPosition: "top",
            marginTop: 8,
          }}
        >
          {children}
        </Box>
      )
    }
    return children;
  }

  function getWrapper (children:JSX.Element):JSX.Element {
    return getBackground(
      <Stack
        direction={"column"}
        flexWrap={"wrap"}
        alignItems={"center"}
        sx={{
          height: "fit-content",
          width: "100%",
        }}
        id={props.data.id}
      >
        {props.data.heading &&  <IDMHeadline data={props.data.heading} />}
        {props.data.text &&  <IDMText data={props.data.text} />}
        {children}
        {props.data.footer &&
          <Stack
            className={"footer"}
            sx={{
              width: "100%",
              direction: "column",
              mt: 8
            }}
          >
            {
              props.data.footer && props.data.footer.map((block:Block, idx:number) => {
                return getBlockElement(block,idx);
              })
            }
          </Stack>
        }
      </Stack>
    );
  }

  const slides:JSX.Element[] = useMemo(() => {
    if(props.data.slides) {
      let cardsPerSlide = 1;
      if(xl) {
        cardsPerSlide = 4;
      } else if(lg) {
        cardsPerSlide = 3;
      } else if (sm) {
        cardsPerSlide = 2;
      }

      const cards:(JSX.Element | null)[] = props.data.slides.map((block, idx) => {
        if(block.type === "card") {
          return <IDMCard
            data={block}
            key={`slider-card-${idx.toString()}`}
            sx={{
              width: `${100/cardsPerSlide}%`,
              maxWidth: `${100/cardsPerSlide}%`,
              height: "auto",
              flexGrow: 1,
            }}
          />
        }
        return null;
      }).filter((el) => el !== null);

      const cardSlides:JSX.Element[] = [];

      for (let i=0; i < Math.ceil(cards.length / cardsPerSlide); i += 1) {
        const start = (i*cardsPerSlide);
        const end = start + cardsPerSlide - 1;
        const cardComponents:(JSX.Element | null)[] = cards.filter((c, idx) => {
          return idx >= start && idx <= end;
        });

        cardSlides.push(
          <Stack
            className={"card-group"}
            direction={"row"}  columnGap={8}
            key={`slider-stack-${i.toString()}`}
            sx={{
              width: "100%",
              mb: 16,
            }}
          >
            {cardComponents}
          </Stack>
        )
      }

      return cardSlides;
    }

    return [];
  },[props.data.slides, sm,  lg, xl]);

  const hideNavigation = useMemo(() => {
    return !(md || lg || xl)
  },[md, lg, xl]);

  return getWrapper(
    <KirbyCarousel
      slidesSx={{px: {xs: 4, md: 18}}}
      indicatorContainerSx={{right: {xs: "1rem", md: "5rem"}}}
      buttonSx={{
        background: "rgba(255,255,255,0.5)",
        mx: 4,
        ":hover, &.active, &.focus ": {
          background: (theme) => theme.palette.common.white,
        },
      }}
      indicatorPos={"right"}
      duration={props.data.duration}
      hideNavigation={hideNavigation}
    >
      {slides}
    </KirbyCarousel>
  );
}
