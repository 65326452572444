import {SocialLink} from "../types/Block";
import {Link} from "@mui/material";
import React from "react";

export default function SocialLinkButton (props:{social:SocialLink}):JSX.Element {
  return (
    <Link
      component={"a"}
      href={props.social.url}
      key={props.social.url}
      target={"_blank"}
      rel="noopener noreferrer"
    >
      <img width={"36px"} height={"36px"} alt={props.social.name} src={props.social.icon}/>
    </Link>
  )
}
