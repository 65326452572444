import React, {useMemo, useState} from 'react'
import {useLocation} from "react-router-dom";
import {Box, Stack, useMediaQuery} from "@mui/material";
import IDMArrowLeft from "../icons/IDMArrowLeft";
import IDMLink from "./IDMLink";
import {IDMScope} from "../types/Scope";
import {BlockType} from "@gk-lab/kirbyreact";
import { useTheme } from '@mui/material/styles';

export default function IDMNavigationLink (props:{url:string, copy:string, onClick?():void}){

  // const matchUrl = useMatch(props.url ?? "/");
  const {pathname} = useLocation();
  const matchUrl = props.url ? pathname.includes(props.url) : false;

  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));

  const arrowWidth = {xs: "3rem", sm: "5rem"};
  const [hovered, setHovered] = useState<Boolean>(false);

  const arrowMargin = useMemo(() => {
    if(matchUrl || hovered) {
      return "0";
    }
    if(matchSm) {
      return "5rem";
    }
    return "3rem";
  }, [matchSm, matchUrl, hovered]);

  return (
    <Stack
      direction={"row"}
      columnGap={2}
       sx={{
         textAlign: "right",
         justifyContent: "flex-end",
       }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      key={props.copy}
      tabIndex={-1}
    >
      <IDMLink
        scope={IDMScope.MAIN}
        className={"main-navigation"}
        data={{type: BlockType.LINK, text: props.copy, to: props.url }}
        onClick={props.onClick}
      />
      <Box sx={{
        width: arrowWidth,
        minWidth: arrowWidth,
        maxWidth: arrowWidth,
        display: "flex",
        alignItems: "center",
        justifyItems: "flex-end",
      }}>
        <Box
          sx={{
            marginLeft: arrowMargin,
            width: "100%",
            transition: "all 0.2s ease-in-out",
            pt: 3,
          }}
        >
          <IDMArrowLeft/>
        </Box>
      </Box>
    </Stack>
  )
}
