import React, {useMemo} from 'react'
import {BlockButton, KirbyProps} from "@gk-lab/kirbyreact";
import {IDMScope} from "../types/Scope";
import {Link as RouterLink} from "react-router-dom";
import {Button} from "@mui/material";
import {cssWrapper} from "../utils/utils";

interface Props extends KirbyProps {
  data:BlockButton;
  scope?:string;
  className?:string;
  onClick?(e?:any):void
}

export default function IDMButton (props:Props):JSX.Element {
  const variant = useMemo(() => {
    switch (props.scope) {
      case IDMScope.FOOTER:
        return "outlined";
      default:
        return "contained";
    }
  }, [props.scope]);

  const className = useMemo(() => {
    const cl:string[] = props.className ? [props.className] : [];
    if(props.scope) {
      cl.push(props.scope);
    }

    if(props.data.align) {
      cl.push(props.data.align);
    }

    if(props.data.variant) {
      cl.push(props.data.variant);
    }

    return cl.join(" ");
  }, [props.className, props.scope, props.data.align, props.data.variant]);

  const to:string = useMemo(() => {
    let str = "/";
    if(props.data.to) {
      str = str + props.data.to;
    }
    if(props.data.anchor) {
      str = str + props.data.anchor;
    }
    return str;
  },[props.data.to, props.data.anchor]);

  function onClick(e:any):void {
    if(props.data.anchor) {
      const getMeTo:HTMLElement | null = document.getElementById(props.data.anchor.replace("#", "").toLowerCase());
      if (getMeTo){
        if(e) e.preventDefault();
        setTimeout(() => {
          getMeTo.scrollIntoView({behavior: 'smooth'});
        }, 250);
      }
    }

    if(props.onClick) {
      props.onClick(e);
    }
  }

  if (props.data.href) {
    return cssWrapper(
      <Button
        variant={variant}
        // component={RouterLink}
        className={className}
        href={props.data.href}
        target={"_blank"}
        sx={props.sx}
        onClick={onClick}
        key={`${props.data.text}`}
      >
        {props.data.text}
      </Button>
      , props.data.css, className)
  } else if (props.data.mailto) {
    return cssWrapper(
      <Button
        variant={variant}
        // component={RouterLink}
        className={className}
        href={`mailto:${props.data.mailto}`}
        sx={props.sx}
        onClick={onClick}
        key={`${props.data.text}`}
      >
        {props.data.text}
      </Button>
      , props.data.css, className)
  }

  return cssWrapper(
    <Button
      variant={variant}
      component={RouterLink}
      className={className}
      to={to}
      sx={props.sx}
      onClick={onClick}
      key={`${props.data.to}-${props.data.text}`}
    >
      {props.data.text}
    </Button>
    , props.data.css, className)


}
