import {
    Block,
    BlockType,
    BlockImage,
    Align,
    BlockHeadline,
    BlockText,
    KirbyBlock,
} from "@gk-lab/kirbyreact";
import {ResponsiveSx} from "@gk-lab/kirbyreact/dist/types/Block";

export enum IDMBlockType {
    DIVIDER = 'divider',
    FORM = 'form',
    IMAGELIST = 'imagelist',
    CARDSPANEL = 'cardspanel',
    SIMPLECARD = 'simplecard',
    SLIDESHOW = 'slideshow',
    PANELLIST = 'panellist',
    STICKY = 'sticky',
    INFOIMAGE = 'infoimage',
    COOKIERESET = 'cookiereset',
}

export type SocialLink = {
    name:string
    url:string
    icon:string
}

export interface BlockIdmHeadline extends BlockHeadline {
    divider?:boolean,
}

export interface BlockCookieReset extends KirbyBlock {
    type: IDMBlockType.COOKIERESET
    text: string;
    variant?: string;
    align?: Align;
}

export interface BlockDivider extends KirbyBlock {
    type: IDMBlockType.DIVIDER
    scope?:string
}

export interface BlockForm extends KirbyBlock {
    type: IDMBlockType.FORM
    cta:string
    action:string
    disclaimerCopy:string
    successCopy?:string
    errorCopy?:string
    infos:Block[]
}


export interface BlockImageList extends KirbyBlock {
    type: IDMBlockType.IMAGELIST | IDMBlockType.PANELLIST
    heading:BlockHeadline
    text?:BlockText
    items:Block[]
    footer?:Block[]
}

export interface BlockCardsPanel extends KirbyBlock{
    type: IDMBlockType.CARDSPANEL
    heading:BlockHeadline
    text?:BlockText
    variant?:string
    header?:Block[]
    items?:Block[]
    footer?:Block[]
}

export interface BlockInfoImage extends KirbyBlock {
    type: IDMBlockType.INFOIMAGE
    image?:BlockImage
    items:Block[]
}

export interface BlockSticky extends KirbyBlock {
    type: IDMBlockType.STICKY
    src?:string;
    alt?:string
    to?:string
    padding?: {
        [key: string]: ResponsiveSx;
    };
}

export interface BlockSimpleCard extends KirbyBlock {
    type: IDMBlockType.SIMPLECARD
    blocks:Block[]
}
export interface BlockSlideshow extends KirbyBlock {
    type: IDMBlockType.SLIDESHOW
    duration?:number
    images:BlockImage[]
}

export interface BlockIDMFooter extends KirbyBlock {
    type: BlockType.FOOTER
    top?:Block[]
    background?:string
    leftheading?:string
    left?:Block[]
    centerheading?:string
    center?:Block[]
    rightheading?:string
    right?:Block[]
    maxwidth?: "sm" | "md" | "lg" | "xl" | "false";
}

export type IDMBlock =
  | BlockForm
  | BlockDivider
  | BlockCardsPanel
  | BlockInfoImage
  | BlockSimpleCard
  | BlockIdmHeadline
  | BlockSlideshow
  | BlockCookieReset
  | BlockImageList;
