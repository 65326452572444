import React, {PropsWithChildren, useMemo} from 'react'
import {Box} from "@mui/material";

interface Props extends PropsWithChildren<any>{
  scope?:string;
  align?:string;
  variant?:string;
}

export default function IDMDivider (props:Props) {

  const margin = useMemo(() => {
    switch (props.align) {
      case "left":
        return "0.5rem 0 1.5rem 0";
      case "right":
        return "0.5rem 0 1.5rem auto";
      case "center":
      default:
        return "0.5rem auto 3rem auto";
    }
  },[props.align]);

  return (
    <Box className={"divider"} sx={
      {
        display: "block",
        width: "20%",
        minWidth: "200px",
        height: "5px",
        backgroundColor: "primary.main",
        margin: margin,
        ...props.sx
      }
    } />
  )
}
