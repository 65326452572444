import React, {PropsWithChildren} from 'react'
import {Box} from "@mui/material";
import {SxProps, Theme} from "@mui/system";
import {BlockSticky} from "../types/Block";
import {Link as RouterLink} from "react-router-dom";

interface Props extends PropsWithChildren<any>{
  data:BlockSticky,
  sx?:SxProps<Theme>,
  scope?: string | undefined,
}

export default function StickyImage (props:Props):JSX.Element | null {
  const {src, alt, to} = props.data;

  // console.log('default - StickyImage: ', props.data)

  if(src) {
    return (
      <Box
        component={to ? RouterLink : "div"}
        className={'sticky-image'}
        aria-label={alt}
        sx={{
          display: "inline-block",
          position: "absolute",
          ...props.sx,
        }}
        to={to}
      >
        <img src={src} alt={alt} />
      </Box>);
  }
  return null;
}
