import React, {PropsWithChildren, useMemo} from 'react'
import {KirbyHeading} from "@gk-lab/kirbyreact";
import {Stack} from "@mui/material";
import IDMDivider from "./IDMDivider";
import {cssWrapper, getResponsiveValues} from "../utils/utils";
import {BlockIdmHeadline} from "../types/Block";

interface Props extends PropsWithChildren<any>{
  data:BlockIdmHeadline,
  scope?: string,
}

export default function IDMHeadline (props:Props) {

  const className = useMemo(() => {
    let cl = [];
    if(props.data.variant) cl.push(props.data.variant);
    if(props.data.align) cl.push(props.data.align);
    return cl.join(" ");
  },[props.data.variant, props.data.align])

  return cssWrapper(
    <Stack
      direction={"column"}
      sx={{
        height: "fit-content",
        width: "100%",
        ...props.sx,
      }}
      id={props.id}
      className={"headline"}
    >
      <KirbyHeading
        data={props.data}
        sx={{
          ...(props.data.maxwidth && {maxWidth: getResponsiveValues(props.data.maxwidth)}),
          ...props.sx
          }}
        className={className}
        id={props.data?.id}
      />
      {props.data.divider && <IDMDivider align={props.data?.align} variant={props.data?.variant}/>}
    </Stack>
  , props.data.css)
}
