import React, {PropsWithChildren} from 'react'
import {Box, Stack} from "@mui/material";
import {Block, KirbyImage} from "@gk-lab/kirbyreact";
import {getBlockElement} from "../utils/sectionUtils";
import {defaultPadding} from "../Theme";
import {BlockInfoImage} from "../types/Block";

interface Props extends PropsWithChildren<any>{
  data:BlockInfoImage,
}

export default function InfoImage (props:Props) {
  const { id, image, items } = props.data;

  return (
    <Box
      id={id}
      className={"infoimage"}
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        className={"infoimage-background"}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden"
        }}
      >
        {image && <KirbyImage data={image} />}
      </Box>
      <Stack
        className={"infoimage-elements"}
        direction={"column"}
        sx={{
          width: {
            xs: "100%",
          },
          position: "relative",
          height: "fit-content",
          px: defaultPadding,
        }}
      >
        {items && items.map((b:Block, idx) => {
          return getBlockElement(
            b, idx, "infoimage"
          )
        })}
      </Stack>
    </Box>
  )
}
