import React from 'react'
import {Box, Stack} from "@mui/material";
import {Block, BlockType, KirbyNavigationOverlay} from "@gk-lab/kirbyreact";
import IDMNavigationLink from "./IDMNavigationLink";
import {accent, defaultPadding} from "../Theme";
import IDMLink from "./IDMLink";
import IDMAppHeader from "./IDMAppHeader";
import {SocialLink} from "../types/Block";
import SocialLinkButton from './SocialLinkButton';

interface Props {
  open:boolean;
  handleClose():void;
  handleClick():void;
  mainItems?:Block[];
  metaItems?:Block[];
  facebook?:string;
  instagram?:string;
  socials?:SocialLink[];
}


export default function IDMNavigation (props:Props):JSX.Element {
  const {mainItems, metaItems} = props;

  function handleClick(){
    setTimeout(() => {
      props.handleClick();
    },250)
  }

  return (
    <KirbyNavigationOverlay
      open={props.open}
      handleClose={props.handleClose}
      handleClick={props.handleClick}
      closeButton={<div/>}
      modalSx={{
        backgroundColor: accent,
        margin: "0 auto",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 5000,
      }}
      containerMaxWidth={false}
      containerSx={{
        maxWidth: "1920px",
      }}
    >
      {
        (mainItems &&
          <Stack
            direction={"column"}
            sx={{
              height: "fit-content",
              maxHeight: "100vh",
              overflow: "hidden"
            }}
          >
            {mainItems?.map((item, idx) => {
              if(item.type === BlockType.LINK) {
                return (
                  <IDMNavigationLink
                    url={item.to}
                    onClick={handleClick}
                    copy={item.text}
                    key={`${idx.toString()}`}
                  />
                )
              }
              return <Box key={`${idx.toString()}`}/>
            })}
          </Stack>
        )
      }
      <Stack
        direction={{xs: "column", sm: "row"}}
        columnGap={3}
        sx={{
          position: "absolute",
          bottom: "1rem",
          right: defaultPadding,
          left: defaultPadding,
          maxWidth: "1920px",
          margin: "0 auto",
          justifyContent: "space-between",
        }}
      >
        <Stack
          gap={3}
          direction={"row"}
          key={`socials`}
        >
          {props.socials && props.socials?.map((sl, idx) => {
            return (
              <SocialLinkButton key={`sl-${idx.toString()}`} social={sl} />
            )
          })}
        </Stack>
        <Box sx={{display: "flex", width: "fit-content"}} className={"meta-navigation"}>
          {metaItems?.map((item,idx) => {
            if(item.type === BlockType.LINK) {
              return (
                <IDMLink
                  key={`meta-${idx.toString()}`}
                  data={item}
                  className={"meta-navigation-link"}
                  onClick={handleClick}
                />
              )
            }
            return <Box key={`${idx.toString()}`}/>
          })}
        </Box>
      </Stack>
      <IDMAppHeader
        sx={{
          gridArea: "header",
          maxHeight: "90px",
          zIndex: 3000
        }}
        maxWidth={false}
        containerSx={{
          maxWidth: "1920px"
        }}
        asOverlay={true}
        open={true}
        handleClose={props.handleClose}
        handleOpen={props.handleClick}
      />
    </KirbyNavigationOverlay>
  )
}

