import React from 'react'
import {BlockType, KirbyProps} from "@gk-lab/kirbyreact";
import {BlockCookieReset} from "../types/Block";
import IDMButton from "./IDMButton";
import {defaultPadding} from "../Theme";
import { Box } from '@mui/material';

interface Props extends KirbyProps {
  data:BlockCookieReset;
  scope?:string;
  className?:string;
  onClick?():void
}

export default function CookieReset (props:Props):JSX.Element {
  function onClick(e:any){
    if(e){
      e.preventDefault();
    }
    // @ts-ignore
    window.ResetManager();
  }

  return (
    <Box
    sx={{
      px: defaultPadding
    }}
    >
      <IDMButton data={{
        ...props.data,
        type: BlockType.BUTTON,
      }}
                 onClick={onClick}
      />
    </Box>
  );

}
