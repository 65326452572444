import React, {ReactNode, useMemo} from 'react'
import {Block, BlockInfoCard, BlockSlider, BlockStage, BlockType, KirbyCarousel} from "@gk-lab/kirbyreact";
import {Box} from "@mui/material";
import IDMStage from "./IDMStage";
import IDMInfoCard from "./IDMInfoCard";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface Props {
  data: BlockSlider,
}

export default function IDMStageSlider (props:Props) {

  const {style} = props.data;

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  function getSlide (slide:Block, idx:number):JSX.Element | null {
    if(slide.type === BlockType.INFOCARD) {
      return <IDMInfoCard data={slide as BlockInfoCard} key={`infocard-${idx.toString()}`} />;
    } else if(slide.type === BlockType.STAGE) {
      return <IDMStage data={slide as BlockStage} key={ `slide-${idx.toString()}` } />;
    }
    return null;
  }

  function getBackground (children:JSX.Element):JSX.Element {
    return (
      <Box
        sx={{
          marginTop: 0,
        }}
        className={"stage-slider"}
      >
        {children}
      </Box>
    );
  }

  function getWrapper (children:JSX.Element):JSX.Element {
    return getBackground(
      <Box mb={0}>
        {children}
      </Box>
    );
  }

  const slides:ReactNode = useMemo(() => {
    if(props.data.slides) {
      return props.data.slides.map((slide:Block, idx:number) => getSlide(slide, idx));
    }
    return [];
  },[props.data.slides]);

  const hideNavigation = useMemo(() => {
    return !(md || lg || xl)
  },[md, lg, xl]);


  return getWrapper(
    <KirbyCarousel
      hideNavigation={style === "stage" || hideNavigation}
      buttonSx={{
        background: (theme) => theme.palette.common.white,
        ":hover, &.active, &.focus ": {
          background: (theme) => theme.palette.common.white,
        },
      }}
      indicatorPos={"right"}
      disableInteraction={style === "stage"}
      navigationPos={"spread"}
      duration={props.data.duration}
    >
      {slides}
    </KirbyCarousel>
  );
}
