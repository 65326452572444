import React, {ReactNode} from 'react'
import {Link as RouterLink} from 'react-router-dom';
import {SectionProject} from '../../types/Section';
import {Box, Button, Typography} from "@mui/material";
import {getBlockElement, wrapWithRow} from "../../utils/sectionUtils";
import {SxProps} from "@mui/system";
import {KirbyFooter, KirbyProps, useAppState} from "@gk-lab/kirbyreact";
import {IDMScope} from "../../types/Scope";

interface Props extends KirbyProps {
  data:SectionProject,
  inline?:boolean,
  parentUrl?:string,
}

function getSection (id:string | undefined,children:ReactNode, sx?:SxProps<any> | undefined, inline?:boolean) {
  return (
    <Box
      component={"section"}
      display={"flex"} flexDirection={"column"}
      sx={{
        padding: "0",
        minHeight: inline ? "unset" : "calc( 100vh - 4rem)",
        ...sx
      }}
      id={id}
    >
      {children}
    </Box>
  )
}

export default function ProjectView (props:Props) {
  const {data} = props;
  const {blocks, stage} = data;
  const appState = useAppState()

  console.log('default - ProjectView: ', data.backbutton)

  const backCopy = data.backbutton || "Zurück";

  return getSection(
    data?.url ?? undefined,
    <>
      {props.parentUrl &&
      wrapWithRow(
        <Box mt={4} mb={4} display={"flex"} alignContent={"center"} justifyContent={"center"} >
          <Button variant={"outlined"} component={RouterLink} to={`/${props.parentUrl}`}>{backCopy}</Button>
        </Box>
        , "", 1
      )
      }
      {stage && stage.map((block, idx) => {
        const component = getBlockElement(block, idx, IDMScope.MAIN);
        return wrapWithRow(component, block.type, idx+1);
      })}
      {data.heading &&
        wrapWithRow(
          <Typography textAlign={"center"} mb={4} variant={"h3"} component={"h3"}>{data.heading}</Typography>
        )
      }
      {data.copy &&
        wrapWithRow(
          <Typography textAlign={"center"} mb={4} variant={"body1"} component={"p"}>{data.copy}</Typography>
          , "", 2
        )
      }
      {blocks && blocks.map((block, idx) => {
        const component = getBlockElement(block, idx, IDMScope.MAIN);
        return wrapWithRow(component, block.type, idx+1);
      })}
      <Box sx={{flexGrow: 1}}/>
      {data.showfooter && <KirbyFooter data={appState.footer} />}
    </>,
    props.sx,
    props.inline
  )
}
