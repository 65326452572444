import React from 'react'
import {Block, BlockStage, IKirbyImage} from "@gk-lab/kirbyreact";
import {Box, Stack } from "@mui/material";
import {getBlockElement} from "../utils/sectionUtils";
import {cssWrapper, getResponsiveValues} from "../utils/utils";

export interface Props {
  data: BlockStage,
}

type Responsive = {
  xs:string|undefined,
  sm?:string|undefined,
  md?:string|undefined,
  lg?:string|undefined,
  xl?:string|undefined
}

export default function IDMStage (props:Props) {
  const { image, responsiveimage, blocks, height, minheight, maxheight, css } = props.data;

  // console.log('default - IDMStage: image', image)
  // console.log('default - IDMStage: responsiveimage', responsiveimage)

  function bi (img:IKirbyImage):string|undefined {
    if(img && img.url){
      return `url('${img.url}')`;
    }
  }

  function getImageSrc():Responsive | string | undefined {
    if(responsiveimage && responsiveimage.xs) {
      return {
        xs: bi(responsiveimage.xs),
        sm: bi(responsiveimage.sm),
        md: bi(responsiveimage.md),
        lg: bi(responsiveimage.lg),
        xl: bi(responsiveimage.xl),
      };
    } else if (image && image.url) {
      return {
        xs: bi(image),
      };
    }
    return undefined
  }

  return (
    <Box
      className={'stage-box'}
      sx={{
        backgroundImage: getImageSrc(),
        backgroundSize: "cover",
        height: height ? getResponsiveValues(height) : {xs: "50vh", sm: "75vh", md: "80vh"},
        width: "100%",
        minHeight: minheight ? getResponsiveValues(minheight) : {xs: "300px", sm: "600px", md: "600px"},
        maxHeight: maxheight ? getResponsiveValues(maxheight) : {xs: "300px", sm: "600px", md: "800px"},
        // paddingTop: {xs: 10, sm: 20, md: 30, lg: 50},
        maxWidth: "100vw",
        overflowX: "hidden"
      }}
    >
      {blocks &&
        cssWrapper(<Stack
          className={"stage-box-blocks"}
          direction={"column"}
          sx={{
            width: {
              xs: "100%"
            },
            height: "fit-content",
            /*px: {
              ...defaultPadding,
            },*/
            // rowGap: 4
          }}
        >
          {props.data.blocks && props.data?.blocks?.map((b:Block, idx) => {
            return getBlockElement(
              b, idx, "stage"
            )
          })}
        </Stack>, css)
      }
    </Box>
  );

}
