import {createTheme} from "@mui/material";
import {IDMScope} from "./types/Scope";

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    main: true;
    [IDMScope.FOOTER]: true;
    [IDMScope.INFOCARD]: true;
    [IDMScope.HEADER]: true;
    [IDMScope.STAGE]: true;
    [IDMScope.CARD]: true;
  }
}
declare module '@mui/material/Toolbar' {
  interface ToolbarPropsVariantOverrides {
    transparent: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    [IDMScope.FOOTER]: true;
    [IDMScope.INFOCARD]: true;
    [IDMScope.INFOCARD_PRIMARY]: true;
    [IDMScope.INFOCARD_SECONDARY]: true;
    [IDMScope.INFOCARD_ACCENT]: true;
    [IDMScope.HEADER]: true;
    [IDMScope.STAGE]: true;
    [IDMScope.MAIN]: true;
    [IDMScope.NAVIGATION]: true;
  }
}

const font = "Barlow";
const fontFamily = [
  font,
  '"Helvetica Neue"',
  "Arial",
  "sans-serif"
].join(",")

const baseTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  }
);

export const footerBackground = "#2b2b2b";

export const white = "#fff";
export const black = "#000";
export const dark = "#333";

export const primary = "#E40134";
export const primaryDark = "#8d061c";

export const secondary = "#565655";
export const secondaryDark = "#2a2a2a";

export const accent = "#203F39";
export const accentDark = "#0e1c19";

export const lightGrey = "#F4F4F4";

export const defaultPadding = {xs: "1rem", sm: "4rem", md: "4rem", lg: "5rem", xl: "6rem"};

export const theme = createTheme({
  breakpoints: baseTheme.breakpoints,
  spacing: 4,
  palette: {
    common: {
      black: "#000",
      white: white,
    },
    primary: {
      main: primary, // color-accent-primary-2
      dark: primaryDark, // color-accent-primary-2-dark
      light: "#d5d5d5", // color-accent-primary-3
    },
    secondary: {
      main: secondary, // color-accent-primary-1
      dark: secondaryDark, // color-accent-primary-2-dark
    },
    error: {
      main: primary,
    },
    warning: {
      main: "#FBC91D", // color-status-warning
      dark: "#AD1035", // color-secundary-1
      light: "rgba(251,201,29,0.15)", // color-status-warning 15 %
    },
    info: {
      main: primary, // color-status-warning-high_prio
      light: "rgba(215,15,55,0.07)", // color-status-warning-high_prio 7 %
    },
    success: {
      main: "#3C992E", // color-status-success
      light: "rgba(10,153,46,0.1)", // color-status-success 10 %
    }
  },
  typography: {
    fontFamily: fontFamily,
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: fontFamily,
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h3',
          subtitle2: 'h3',
          body1: 'p',
          body2: 'p',
        },
      },
      styleOverrides: {
        root: {
          color: secondaryDark,
          fontSize: "1.625rem",
          lineHeight: 1.2,
          '&.left': {
            textAlign: "left",
          },
          '&.center': {
            textAlign: "center",
          },
          '&.right': {
            textAlign: "right",
          },
          '&.primary': {
            color: secondaryDark,
          },
          '&.secondary': {
            color: white,
          },
          '&.accent': {
            color: white,
          },
          '& p, a': {
            marginBlockStart: "0 !important",
            marginBlockEnd: "1rem !important",
            marginInlineStart: "0 !important",
            marginInlineEnd: "0 !important",
            textAlign: "inherit",
          },
          'p': {
            minHeight: "1rem",
          },
          '& a': {
            color: primary,
            whiteSpace: "nowrap",
          },
          '& a:hover': {
            color: primaryDark,
          },
          '& h2,h3,h4,h5,h6': {
            textTransform: "uppercase",
            textAlign: "inherit",
            lineHeight: 1.1,
            marginBottom: "1rem",
          },
          '& h1': {

          },
          'h3': {
            fontSize: "2rem",
            lineHeight: 1,
            fontWeight: "bold",
            textTransform: "uppercase",
            marginTop: "1rem",
            marginBottom: "1rem",
          },
          'h4': {
            fontSize: "1.5rem",
            lineHeight: 1,
            fontWeight: "bold",
            textTransform: "uppercase",
            marginTop: "1rem",
            marginBottom: "1rem",
          },
          [baseTheme.breakpoints.up('sm')]:{
            '& h2,h3,h4,h5,h6': {
              lineHeight: 1.2,
              marginBottom: "1.5rem"
            },
            '& h2': {
              fontSize: "2rem",
            },
            '& h3': {
              fontSize: "2.5rem",
              marginBottom: "0",
            }
          },
          [baseTheme.breakpoints.up('lg')]:{
            '& h3': {
              fontSize: "3rem",
              marginTop: "3rem",
            },
          }
        },
        body1: {
          paddingBottom: "1rem",
          "&:not(.panel)":{
            // maxWidth: "calc(100% - 2rem)",
          },
          // Inline Styles
          '& p, h2, h3, h4, h5, h6': {
            paddingLeft: "0",
            paddingRight: "0",
          },
          '&.center': {
            alignSelf: "center",
          },
          [baseTheme.breakpoints.up('sm')]:{
            lineHeight: 1.5,
          },
        },
        body2: {
          paddingBottom: 0,
          '& p, a': {
            // marginBlockEnd: "0",
          },
          '&.copyright': {
            fontSize: "1rem",
          },
        },
        h1: {
          // SLIDER
          fontSize: "2.25rem",
          lineHeight: 0.98,
          fontWeight: "bold",
          textTransform: "uppercase",
          position: "relative",
          maxWidth: "100%",
          "&.left, &.right": {
            "&::before": {
              position: "absolute",
              width: "129px",
              height: "16px",
            }
          },
          "&.left": {
            marginLeft: "2rem",
            "&::before": {
              content: `url(svg/arrowtoright.svg)`,
              left: "-139px",
              top: "-4px",
            },
          },
          "&.right": {
            marginRight: "2rem",
            alignSelf: "flex-end",
            "&::before": {
              content: `url(svg/arrowtoleft.svg)`,
              right: "-139px",
              top: "-6px",
            },
          },
          "&.center": {
            alignSelf: "center",
          },
          [baseTheme.breakpoints.up('sm')]:{
            fontSize: "4rem",
            "&.left": {
              "&::before": {
                top: "-12px",
              },
            },
            "&.right": {
              "&::before": {
                top: "-14px",
              },
            },
          },
          [baseTheme.breakpoints.up('md')]:{
            fontSize: "5rem",
            "&.left": {
              "&::before": {
                top: "-20px",
              },
            },
            "&.right": {
              "&::before": {
                top: "-18px",
              },
            },
          }
        },
        h2: {
          fontSize: "2.25rem",
          fontWeight: "bold",
          textTransform: "uppercase",
          marginTop: "1rem",
          marginBottom: "1rem",
          lineHeight: "0.95",
          position: "relative",
          [baseTheme.breakpoints.up('xl')]:{
            fontSize: "5rem",
          },
          "&.left, &.right": {
            "&::before": {
              position: "absolute",
              width: "129px",
              height: "16px",
            }
          },
          "&.left": {
            marginLeft: 0,
            "&::before": {
              content: `url(svg/arrowtoright.svg)`,
              left: "-139px",
              top: "-6px",
            },
          },
          "&.right": {
            marginRight: 0,
            "&::before": {
              content: `url(svg/arrowtoleft.svg)`,
              right: "-139px",
              top: "-4px",
            },
          },
          [baseTheme.breakpoints.up('sm')]:{
            fontSize: "3rem",
            "&.left": {
              "&::before": {
                top: "-10px",
              },
            },
            "&.right": {
              "&::before": {
                top: "-12px",
              },
            },
          },
          [baseTheme.breakpoints.up('md')]:{
            marginTop: "2rem",
            fontSize: "4rem",
            "&.left": {
              "&::before": {
                top: "-16px",
              },
            },
            "&.right": {
              "&::before": {
                top: "-18px",
              },
            },
          }
        },
        h3: {
          fontSize: "2rem",
          lineHeight: 1.15,
          fontWeight: "bold",
          textTransform: "uppercase",
          marginTop: "2rem",
          marginBottom: "1rem",
          [baseTheme.breakpoints.up('sm')]:{
            fontSize: "2.5rem",
          },
          [baseTheme.breakpoints.up('md')]:{
            marginTop: "3rem",
            fontSize: "3rem",
          },
          [baseTheme.breakpoints.up('xl')]:{
            marginTop: "5rem",
          },
          '&.primary': {
          },
          '&.center': {
            alignSelf: "center",
          },
        },
        h4: {
          fontSize: "2rem",
          fontWeight: "bold",
          marginTop: "2rem",
          marginBottom: "1rem",
          textTransform: "uppercase",
          [baseTheme.breakpoints.up('sm')]:{
            fontSize: "2.5rem",
          },
          [baseTheme.breakpoints.up('xl')]:{
            fontSize: "3rem",
          }
        },
        h5: {
          fontSize: "1.5rem",
          fontWeight: "bold",
          color: accent,
          margin: "0",
          lineHeight: "1.8",
          marginBottom: "0",
          [baseTheme.breakpoints.up('lg')]:{
            fontSize: "1.5rem",
          }
        },
        h6: {
          fontSize: "1rem",
          transform: "none",
          fontWeight: "lighter",
          marginBottom: "0.25rem",
          marginTop: "0.25rem",
          [baseTheme.breakpoints.up('lg')]:{
            fontSize: "1.25rem",
          }
        },
        subtitle1: {
          fontSize: "2.0rem",
          lineHeight: "1",
          fontWeight: "bold",
          textTransform: "uppercase",
          marginTop: "0",
          marginBottom: "1rem",
          [baseTheme.breakpoints.up('lg')]:{
            fontSize: "3rem",
          }
        },
        subtitle2: {
          color: white,
          fontSize: "2.0rem",
          lineHeight: "1",
          fontWeight: "bold",
          textTransform: "uppercase",
          marginTop: "0",
          marginBottom: "1rem",
          [baseTheme.breakpoints.up('lg')]:{
            fontSize: "3rem",
          }
        }
      },
      variants: [
        {
          props: {variant: IDMScope.FOOTER},
          style: {
            color: white,
            paddingBottom: "0",
            textAlign: "center",
            '& a, p': {
              color: white,
              textAlign: "center",
              marginBlockEnd: "0 !important",
              textDecoration: "none",
            },
            '& a:hover': {
              // fontWeight: "bold",
              color: primary,
            },
            [baseTheme.breakpoints.up('lg')]:{

            }
          },
        },
        {
          props: {variant: IDMScope.INFOCARD_PRIMARY},
          style: {
            color: secondaryDark,
            paddingBottom: "1rem",
            '& p, a': {
              lineHeight: 1.2,
            },
            '& h2,h3,h4,h5,h6': {
              marginTop: 0,
              marginBottom: "1.5rem",
            },
            [baseTheme.breakpoints.up('md')]:{
              lineHeight: 1.5,
              '& p': {
              },
            }
          },
        },
        {
          props: {variant: IDMScope.INFOCARD_SECONDARY},
          style: {
            color: secondaryDark,
            paddingBottom: "1rem",
            '& p, a': {
              lineHeight: 1.2,
            },
            '& h2,h3,h4,h5,h6': {
              marginTop: 0,
              marginBottom: "1.5rem",
            },
            "h2": {
              position: "relative",
              fontSize: "2.25rem",
              lineHeight: 0.98,
              fontWeight: "bold",
              textTransform: "uppercase",
              "&::before": {
                position: "absolute",
                width: "129px",
                height: "16px",
                content: `url(svg/arrowtoright.svg)`,
                left: "-140px",
                top: "-4px",
              }
            },
            [baseTheme.breakpoints.up('md')]:{
              lineHeight: 1.5,
              '& p': {
              },
            }
          },
        },
        {
          props: {variant: IDMScope.INFOCARD_ACCENT},
          style: {
            color: white,
            paddingBottom: "1rem",
            '& p, a': {
              lineHeight: 1.2,
            },
            '& h2,h3,h4,h5,h6': {
              marginTop: 0,
              marginBottom: "1.5rem",
            },
            [baseTheme.breakpoints.up('lg')]:{
              lineHeight: 1.5,
              '& p': {
              },
            }
          },
        },
      ],
    },
    MuiButton:{
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderWidth: 0,
          marginBottom: "1.5rem",
          width: "fit-content",
          textTransform: "uppercase",
          "& :hover, &:focus": {
            backgroundColor: primaryDark,
          },
          "& svg": {
            background: "transparent !important"
          },
          fontSize: "1.5rem",
          [baseTheme.breakpoints.up('md')]:{
            fontSize: "1.625rem",
          },
          '&.right': {
            alignSelf: "flex-end",
            justifySelf: "flex-end",
          },
          '&.left': {
            alignSelf: "flex-start",
            justifySelf: "flex-start",
          },
          '&.center': {
            alignSelf: "center",
            justifySelf: "center",
          },
          '&.card-action': {
            "div > span.card-cta": {
              color: primary,
              fontSize: "1rem",
              fontWeight: "bold",
            },
            "&:hover": {
              "div": {
                backgroundColor: primary,
              },
              color: white,
              "div > span.card-cta": {
                color: white,
                backgroundColor: primary,
              },
            },
          },
        }
      },
      variants: [
        {
          props: {variant: 'contained'},
          style: {
            color: white,
            alignSelf: "center",
            textAlign: "center",
            lineHeight: 1.1,
            padding: "1rem",
            backgroundColor: primary,
            "&.active": {
              color: white,
              backgroundColor: primary,
            },
            "&:hover,&:focus": {
              color: white,
              backgroundColor: primaryDark,
            }
          },
        },
      ],
    },
    MuiModal:{
      styleOverrides: {
        root: {
          // backgroundColor: accent,
        }
      }
    },
    MuiContainer:{
      styleOverrides: {
        root: {
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
          ".imagelist": {
            ".container-elements": {
              marginTop: "-1px",
              marginLeft: "-1px",
            },
            ".image": {
              outline: "1px solid #707070",
              marginTop: "1px",
              marginLeft: "1px",
            },
          },
          ".infoimage": {
            "h2,h3,h4": {
              position: "relative",
              marginTop: "1rem",
              /*
              "&::before": {
                position: "absolute",
                width: "129px",
                height: "16px",
              },
              "&.left": {
                marginLeft: "4rem",
                "&::before": {
                  left: "-139px",
                  top: "-4px",
                },
              },
              "&.right": {
                marginRight: "4rem",
                "&::before": {
                  right: "-139px",
                  top: "-6px",
                },
              },
              "&.primary": {
                "&.left": {
                  "&::before": {
                    content: `url(svg/arrowtoright.svg)`,
                  },
                },
                "&.right": {
                  "&::before": {
                    content: `url(svg/arrowtoleft.svg)`,
                  },
                },
              },
              "&.secondary": {
                "&.left": {
                  "&::before": {
                    content: `url(svg/arrowtoright-white.svg)`,
                  },
                },
                "&.right": {
                  "&::before": {
                    content: `url(svg/arrowtoleft-white.svg)`,
                  },
                },
              }
              */
            }
          },
          ".footer-meta-container": {
            backgroundColor: white,
            padding: "1rem",
            flexDirection: "column",
            justifyContent: "column",
            [baseTheme.breakpoints.up('sm')]:{
              padding: "1rem 8rem 2rem 8rem",
              flexDirection: "row",
              justifyContent: "space-between",
            },
            [baseTheme.breakpoints.up('lg')]:{
              padding: "1rem 10rem 2rem 10rem",
            },
            [baseTheme.breakpoints.up('xl')]:{
              padding: "1rem 12rem 2rem 12rem",
            },
          },
          ".section-stack": {
            ">div.headline,>div.css-wrapper>div.headline,>p.copy,>div.css-wrapper>p.copy": {
              padding: "0 1rem",
              [baseTheme.breakpoints.up('md')]:{
                padding: "0 4rem",
              },
              [baseTheme.breakpoints.up('lg')]:{
                padding: "0 5rem",
              },
              [baseTheme.breakpoints.up('xl')]:{
                padding: "0 6rem",
              },
            }
          },
          ".css-wrapper": {
            '&.left': {
              justifyContent: "flex-start",
            },
            '&.center': {
              justifyContent: "center",
            },
            '&.right': {
              justifyContent: "flex-end",
            },
          }
        },
        maxWidthXl: {
          paddingLeft: "0",
          paddingRight: "0",
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        }
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "label > span": {
            fontSize: "1rem",
          }
        },
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "4rem",
          backgroundColor: white,
          color: dark,
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        }
      },
      variants: [
        {
          props: {variant: 'transparent'},
          style: {
            background: "transparent",
          },
        }
      ],
    },
    MuiLink: {
      defaultProps: {
        fontFamily: fontFamily,
      },
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          '&.primary': {
            textDecorationColor: secondary,
            '&:hover,&:focus': {
              color: primary,
              textDecorationColor: primary,
            },
          },
          '&.secondary, &.meta-navigation-link': {
            color: secondary,
            textDecorationColor: secondary,
            '&:hover,&:focus': {
              color: primary,
              textDecorationColor: primary,
            },
          },
          '&.footer-meta-navigation': {
            fontSize: "1rem",
            "&:not(:last-child):after": {
              content: '"|"',
              margin: "0 0 0 0.5rem",
              textDecoration: "none",
            },
            '&:hover,&:focus': {
              "&::after": {
                color: secondary,
              }
            },
          },
          '&.meta-navigation-link': {
            color: white,
            textDecoration: "none",
            "&:not(:last-child):after": {
              content: '"|"',
              margin: "0 0.5rem",
              textDecoration: "none",
            },
            '&:hover,&:focus': {
              "&::after": {
                color: white,
              }
            },
          },
          '&.accent': {
            color: white,
            textDecorationColor: white,
            '&:hover,&:focus': {
              color: primary,
              textDecorationColor: primary,
            },
          },
          '&.footer.secondary': {
            color: white,
            textDecorationColor: white,
            '&:hover,&:focus': {
              color: primary,
              textDecorationColor: primary,
            },
          },
          '&.main-navigation': {
            color: white,
            textTransform: "uppercase",
            textDecoration: "none",
            fontSize: "3rem",
            fontWeight: "bold",
            marginBottom: 0,
            lineHeight: 1,
            paddingTop: 0,
            [baseTheme.breakpoints.up('sm')]:{
              fontSize: "6rem",
            },
            [baseTheme.breakpoints.up('md')]:{
              fontSize: "6rem",
            },
            '&:hover,&:focus': {
              color: primary,
              textDecoration: "none",
            },
            '&.active': {
              color: primary,
              textDecoration: "none",
            },
          },
          '&.center': {
            margin: "0 auto",
          }
        }
      },
      variants: [
        {
          props: {variant: IDMScope.MAIN},
          style: {
            color: white,
            textTransform: "uppercase",
            background: "transparent",
            textDecoration: "none",
            textAlign: "right",
            fontWeight: "bold",
            fontSize: "3rem",
            marginBottom: 0,
            lineHeight: 1,
            paddingTop: 0,
          },
        },
        {
          props: {variant: IDMScope.FOOTER},
          style: {
            color: white,
            textDecorationColor: white,
            textAlign: "center",
            marginBlockEnd: "0 !important",
            ":hover":{
              // fontWeight: "bold",
              color: primary,
              // textDecorationColor: white,
            },
            [baseTheme.breakpoints.up('lg')]:{
              // fontSize: "1rem",
            }
          },
        },
        {
          props: {variant: IDMScope.NAVIGATION},
          style: {
            color: white,
            textDecoration: "none",
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: "1.25rem",
            ":hover":{
              color: primary,
            },
            [baseTheme.breakpoints.up('sm')]:{
              fontSize: "2rem",
            }
          },
        },

      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
        },
        vertical: {
          borderColor: "rgba(255,255,255,0.3)"
        }
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          borderBottomRightRadius: "8px",
          borderBottomLeftRadius: "8px",
          // boxShadow: "none",
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "2rem 1rem",
          ".card-heading": {
            // textAlign: "center",
            color: secondaryDark,
            fontSize: "1.25rem",
            fontWeight: "bold",
            marginBottom: "1.5rem",
            lineHeight: 1,
            textTransform: "upperCase",
            padding: 0,
            maxHeight: "3.5rem",
            textAlign: "left",
            [baseTheme.breakpoints.up('md')]:{
              fontSize: "1.75rem",
            }
          },
          ".card-subtitle": {
            // textAlign: "center",
            fontSize: "1rem",
            marginBottom: "0.25rem",
            lineHeight: 1,
            padding: 0,
            fontWeight: "bold",
            textAlign: "left",
            color: "#707070",
          },
          ".card-copy": {
            // textAlign: "center",
            fontSize: "1.25rem",
            margin: "0.5rem 0 0.25rem 0",
            padding: 0,
            "> p": {
              margin: 0,
            }
          },
          [baseTheme.breakpoints.up('md')]:{
            ".card-heading": {
              // textAlign: "left"
            },
            ".card-subtitle": {
              // textAlign: "left"
            },
            ".card-copy": {
              // textAlign: "left"
            },
          }
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "0rem 1rem 2rem 1rem",
        }
      }
    },
  }
});
