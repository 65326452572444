import React, {ReactNode, useMemo} from 'react'
import {Block, BlockInfoCard, BlockSlider, KirbyCarousel} from "@gk-lab/kirbyreact";
import {Stack} from "@mui/material";
import IDMInfoCard from "./IDMInfoCard";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface Props {
  data: BlockSlider,
}

export default function IDMInfoCardSlider (props:Props) {

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  function getSlide (slide:Block, idx:number):JSX.Element {
    return <IDMInfoCard data={slide as BlockInfoCard} key={`infocard-${idx.toString()}`} />;
  }
  function getWrapper (children:JSX.Element):JSX.Element {
    return (
      <Stack
        direction={"column"}
        alignItems={"center"}
        className={"infocard-slider"}
        mt={12}
        sx={{
          height: "fit-content",
        }}
      >
        {children}
      </Stack>
    )
  }

  const slides:ReactNode = useMemo(() => {
    if(props.data.slides) {
      return props.data.slides.map((slide:Block, idx:number) => getSlide(slide, idx));
    }
    return [];
  },[props.data.slides]);

  const hideNavigation = useMemo(() => {
    return !(md || lg || xl)
  },[md, lg, xl]);

  return getWrapper(
    <KirbyCarousel
      slidesSx={{px: 0}}
      buttonSx={{
        background: "transparent",
        ":hover, &.active, &.focus ": {
          background: "transparent",
        },
      }}
      hideNavigation={hideNavigation}
      indicatorPos={"right"}
    >
      {slides}
    </KirbyCarousel>
  );
}
