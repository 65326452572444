import React, {PropsWithChildren, useRef} from 'react'
import {Box, Stack} from "@mui/material";
import {Align, Block, BlockInfoCard, BlockType} from "@gk-lab/kirbyreact";
import {getBlockElement} from "../utils/sectionUtils";
import {accent, defaultPadding, lightGrey, white} from "../Theme";
// import Image from "./Image";

interface Props extends PropsWithChildren<any>{
  data:BlockInfoCard,
}

export default function IDMInfoCard (props:Props) {
  const { align, background } = props.data;
  const contentRef = useRef<HTMLDivElement | null>(null);

  function getBackgroundColor():string {
    switch (props.data.variant) {
      case "secondary":
        return lightGrey;
      case "accent":
        return accent;
      case "primary":
      default:
        return white;
    }
  }
  function getBackground ():JSX.Element | null {
    console.log('IDMInfoCard - getBackground: ', background)
    if(background) {
      switch (background.type) {
        case BlockType.IMAGE:
          console.log('getBackground - set image!!!: ', background)
          return getBlockElement(background, 0);
        default:
          return (
            <Box
              className={"background-slider"}
              sx={{
                width: "100%",
              }}
            >
              {getBlockElement(background, 0)}
            </Box>
          );
      }
    }
    return null;
  }
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {xs: "column", lg: "row"},
        position: "relative",
        backgroundColor: getBackgroundColor(),
        width: "100%",
        // minHeight: minHeight,
        alignItems: "center",
        pt: 0,
      }}
      className={"info-card"}
    >
      {getBackground()}
      <Box
        className={"info-card-container"}
        sx={{
          position: {xs: "unset", lg: "absolute"},
          //overflow: {xs: "unset", md: "absolute"},
          display: "flex",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: "100%",
          overflow: "auto",
          maxWidth: "100%",
          justifyContent:
            (align === Align.left && "flex-start")
            || (align === Align.right && "flex-end")
            || "center",
        }}
      >
        <Stack
          className={"info-card-elements"}
          direction={"column"}
          sx={{
            width: {
              xs: "100%",
              lg: "45%",
            },
            height: "fit-content",
            pb: 4,
            px: {
              ...defaultPadding,
              xs: "2rem",
            },
            // rowGap: 4
          }}
          ref={contentRef}
        >
          {props.data.blocks && props.data?.blocks?.map((b:Block, idx) => {
            return getBlockElement(
              b, idx, "infocard"
            )
          })}
        </Stack>
      </Box>
    </Box>
  )
}
