import React, {ReactNode} from 'react'
import {SectionCase} from '../../types/Section';
import {Box} from "@mui/material";
import {getBlockElement, wrapWithRowIDM} from "../../utils/sectionUtils";
import {SxProps} from "@mui/system";
import {BlockType, KirbyProps} from "@gk-lab/kirbyreact";
import {IDMScope} from "../../types/Scope";
import IDMFooter from "../../components/IDMFooter";

interface Props extends KirbyProps {
  data:SectionCase,
  inline?:boolean,
  parentUrl?:string,
}

function getSection (id:string | undefined,children:ReactNode, sx?:SxProps<any> | undefined, inline?:boolean) {
  return (
    <Box
      component={"section"}
      display={"flex"} flexDirection={"column"}
      sx={{
        padding: "0",
        minHeight: inline ? "unset" : "calc( 100vh - 4rem)",
        ...sx
      }}
      id={id}
    >
      {children}
    </Box>
  )
}

export default function CaseView (props:Props) {
  const {data} = props;
  const {blocks} = data;

  return getSection(
    data?.url ?? undefined,
    <>
      {/*{props.parentUrl &&
      wrapWithRow(
        <Box mt={4} mb={4} display={"flex"} alignContent={"center"} justifyContent={"center"} >
          <Button variant={"outlined"} component={RouterLink} to={`/${props.parentUrl}`}>{backCopy}</Button>
        </Box>
        , "", 1
      )
      }*/}
      {blocks && blocks.map((block, idx) => {
        const component = getBlockElement(block, idx, IDMScope.MAIN);
        return wrapWithRowIDM(component, BlockType.PAGE, idx+1);
      })}
      <Box sx={{flexGrow: 1}}/>
      {data.showfooter && <IDMFooter getBlockElement={getBlockElement} />}
    </>,
    props.sx,
    props.inline
  )
}
