import {SectionCase, SectionProject} from "../types/Section";
import {
  Block,
  BlockType,
  getKirbyBlockElement,
  KirbyPanel,
  KirbySection,
  Section
} from "@gk-lab/kirbyreact";
import ProjectView from "../views/ProjectView/ProjectView";
import KirbyForm from "../components/KirbyForm/KirbyForm";
import React, {ReactNode} from "react";
import {Container, Grid, Stack} from "@mui/material";
import IDMHeadline from "../components/IDMHeadline";
import IDMFooter from "../components/IDMFooter";
import IDMLink from "../components/IDMLink";
import IDMDivider from "../components/IDMDivider";
import {IDMBlock, IDMBlockType} from "../types/Block";
import IDMText from "../components/IDMText";
import IDMInfoCard from "../components/IDMInfoCard";
import IDMButton from "../components/IDMButton";
import IDMStageSlider from "../components/IDMStageSlider";
import IDMCardSlider from "../components/IDMCardSlider";
import IDMInfoCardSlider from "../components/IDMInfoCardSlider";
import IDMImageSlider from "../components/IDMImageSlider";
import IDMCardGroup from "../components/IDMCardGroup";
import ImageList from "../components/ImageList";
import CardsPanel from "../components/CardsPanel";
import SimpleCard from "../components/SimpleCard";
import CaseView from "../views/CaseView/CaseView";
import InfoImage from "../components/InfoImage";
import CookieReset from "../components/CookieReset";

export type IDMSSection =
  | SectionProject
  | SectionCase
  | Section;

export function wrapWithRowIDM (element:ReactNode | null, type?:BlockType | "", idx?:number):JSX.Element | ReactNode | null {
  const key = idx || type ? `${type ?? "block"}-${idx ? idx.toString() : "0"}` : ""

  switch (type) {
    case BlockType.ANIMATIONS:
    case BlockType.ANIMATION:
    case BlockType.SLIDER:
    case BlockType.PAGECONTAINER:
    case BlockType.FRAME:
    case BlockType.PAGE:
      return (
        <Container
          maxWidth={false}
          sx={{
            maxWidth: "1920px",
          }}
          key={key}
        >
          <Stack direction={"column"} className={"section-stack"}>
            {element}
          </Stack>
        </Container>
      )
    default:
      return element;
    /*return (
      <Container maxWidth={false} sx={{maxWidth: "1920px"}} key={key}>
        {element}
      </Container>
    )
      /*return (
        <Container maxWidth={false} sx={{maxWidth: "1920px"}} key={key}>
          <Grid container rowSpacing={4} justifyContent={"center"}>
            <Grid item xs={0} lg={0} xl={0}/>
            <Grid item xs={12} lg={12} xl={12} textAlign={"center"}>
              {element}
            </Grid>
            <Grid item xs={0} lg={0} xl={0} />
          </Grid>
        </Container>
      )*/
  }
}

/**
 * Get an route-template by section.id
 * @param section
 * @param parentUrl
 * @param inline
 */
export function getSectionElement (section:IDMSSection, parentUrl:string, inline:boolean) {
  switch (section.id) {
    case 'project':
      return (
        <ProjectView
          key={section.url}
          data={section}
          inline={false}
          parentUrl={parentUrl}
      />
    )
    case 'case':
      return (
        <CaseView
          key={section.url}
          data={section}
          inline={false}
          parentUrl={parentUrl}
      />
    )
    case 'default':
    default:
      return (
        <KirbySection
          key={section.url}
          data={section}
          inline={false}
          parentUrl={parentUrl}
          getBlockElement={getBlockElement}
          getFooter={() => <IDMFooter getBlockElement={getBlockElement} />}
          wrapWithRow={wrapWithRowIDM}
      />
    )
  }
}


export function getBlockElement (b:Block | IDMBlock, idx:number, scope?:string):JSX.Element | null {
  // console.log('getBlockElement - getBlockElement: ', b.type, b)
  if (b) {
    switch (b.type) {
      case IDMBlockType.IMAGELIST:
      case IDMBlockType.PANELLIST:
        return <ImageList key={`imagelist-${idx.toString()}`} data={b} scope={b.type} />
      case IDMBlockType.INFOIMAGE:
        return <InfoImage key={`infoimage-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.PANEL:
        return <KirbyPanel key={`panel-${idx.toString()}`} data={b} scope={scope} getBlockElement={getBlockElement} />
      case IDMBlockType.CARDSPANEL:
        return <CardsPanel key={`cardspanel-${idx.toString()}`} data={b} scope={scope} />
      case IDMBlockType.SIMPLECARD:
        return <SimpleCard key={`simplecard-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.HEADLINE:
        return <IDMHeadline key={`heading-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.TEXT:
        return <IDMText key={`text-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.BUTTON:
        return <IDMButton key={`button-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.LINK:
        return <IDMLink key={`link-${idx.toString()}`} data={b} scope={scope} />
      case IDMBlockType.DIVIDER:
        return <IDMDivider key={`divider-${idx.toString()}`} data={b} scope={scope}/>
      case IDMBlockType.SLIDESHOW:
        return <IDMImageSlider key={`slideshow-${idx.toString()}`} data={{
          ...b,
          type: BlockType.SLIDER
          }}
          hideNavigation
        />
      case IDMBlockType.COOKIERESET:
        return <CookieReset key={`cookiereset-${idx.toString()}`} data={b} scope={scope} />
      case BlockType.SLIDER:
        switch (b.style) {
          case "cardlist":
            return <IDMCardSlider key={`cardslider-${idx.toString()}`} data={b} />;
          case "infocards":
            return <IDMInfoCardSlider key={`infocardslider-${idx.toString()}`} data={b} />;
          case "images":
            return <IDMImageSlider key={`imagelider-${idx.toString()}`} data={b} />;
          case "stage":
          case "infostage":
          default:
            return <IDMStageSlider key={`stageslider-${idx.toString()}`} data={b} />;
        }
      case BlockType.CARDS:
        return <IDMCardGroup key={`card-group-${idx.toString()}`} data={b} />
      case "infocard":
        return <IDMInfoCard key={`infocard-${idx.toString()}`} data={b} scope={scope} />;
      case IDMBlockType.FORM:
        return <KirbyForm key='anchor-form-${idx.toString()' data={b} />
      default:
        return getKirbyBlockElement(b, idx, scope);

    }
  }
  return null;
}

export function wrapWithRow (element:ReactNode, type?:BlockType | "", idx?:number) {
  const key = idx || type ? `${type ?? "block"}-${idx ? idx.toString() : "0"}` : ""

  switch (type) {
    case BlockType.ANIMATIONS:
    case BlockType.ANIMATION:
    case BlockType.SLIDER:
    case BlockType.PAGECONTAINER:
    case BlockType.FRAME:
      return (
        <Container maxWidth={"xl"} key={key}>
      <Grid container>
      <Grid item xs={12}>
      {element}
      </Grid>
      </Grid>
      </Container>
    )
    default:
      return (
        <Container maxWidth={"xl"} key={key}>
      <Grid container rowSpacing={4} justifyContent={"center"}>
      <Grid item xs={0} lg={2} xl={2} />
    <Grid item xs={12} lg={8} xl={8}>
      {element}
      </Grid>
      <Grid item xs={0} lg={2} xl={2} />
    </Grid>
    </Container>
    )
  }
}