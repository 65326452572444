import React, {useMemo} from 'react'
import {BlockLink, KirbyProps} from "@gk-lab/kirbyreact";
import {Link} from "@mui/material";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {cssWrapper} from "../utils/utils";

interface Props extends KirbyProps {
  data:BlockLink;
  scope?:string;
  className?:string;
  onClick?():void;
}

export default function IDMLink (props:Props):JSX.Element {

  const {pathname} = useLocation();
  const matchUrl = props.data.to ? pathname.includes(props.data.to) : false;

  const className = useMemo(() => {
    const cl:string[] = props.className ? [props.className] : [];

    if(props.data.align) {
      cl.push(props.data.align);
    }
    if(props.data.variant) {
      cl.push(props.data.variant);
    }
    if(props.scope) {
      cl.push(props.scope);
    }

    if(matchUrl) {
      cl.push("active");
    }
    return cl.join(" ");
  }, [matchUrl, props.className, props.data.align, props.data.variant, props.scope]);

  const to:string = useMemo(() => {
    let str = "/";
    if(props.data.to) {
      str = str + props.data.to;
    }
    if(props.data.anchor) {
      str = str + props.data.anchor;
    }
    return str;
  },[props.data.to, props.data.anchor]);

  return cssWrapper(
      <Link
        component={RouterLink}
        className={className}
        to={to}
        sx={props.sx}
        onClick={props.onClick}
        key={`${props.data.to}-${props.data.text}`}
      >{props.data.text}</Link>
    , props.data.css);
}
