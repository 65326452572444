import React, {useMemo} from 'react'
import {BlockCardsPanel} from "../types/Block";
import {Box, Stack} from "@mui/material";
import {Block} from "@gk-lab/kirbyreact";
import {getBlockElement} from "../utils/sectionUtils";
import {defaultPadding} from "../Theme";

interface Props {
  data:BlockCardsPanel;
  scope?: string | undefined;
}

export default function CardsPanel (props:Props):JSX.Element {

  // console.log('default - CardsPanel: ', props.data)
  
  const cards = useMemo(() => {
    if(props.data.items && props.data.items.length > 0) {
      const w:string = `${Math.floor(100 / props.data.items.length)}%`;
      return (
        props.data.items.map((block:Block, idx:number) => {
          return (
            <Box
              key={`panel-${idx.toString()}`}
              sx={{
                width: {xs: "100%", sm: w},
              }}
            >
              {getBlockElement(block,idx)}
            </Box>
          );
        })
      )
    }
    return null;
  }, [props.data.items]);

  const backgroundColor = useMemo(() => {
    switch(props.data.variant) {
      case "secondary":
        return "#F4F4F4";
      case "accent":
        return "#203F39";
      case "primary":
      default:
        return "transparent";
    }
  }, [props.data.variant]);

  return (
    <Stack
      direction={"row"}
      flexWrap={"wrap"}
      alignItems={"center"}
      className={"cards-panel"}
      sx={{
        height: "fit-content",
        width: "100%",
        mb: 0,
        backgroundColor: backgroundColor,
      }}
    >
      {props.data.header &&
        <Stack
          className={"header"}
          sx={{
            width: "100%",
            direction: "column",
            mt: 8,
            mb: 0,
            px: defaultPadding,
          }}
        >
          {
            props.data.header.map((block:Block, idx:number) => {
              return getBlockElement(block,idx);
            })
          }
      </Stack>
      }
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          mb: 8,
          flexWrap: "wrap",
        }}
      >
        {cards}
      </Stack>
      {props.data.footer &&
        <Stack
          className={"footer"}
          sx={{
            width: "100%",
            direction: "column",
            mt: 8,
            mb: 16,
            px: defaultPadding,
          }}
        >
          {
            props.data.footer && props.data.footer.map((block:Block, idx:number) => {
              return getBlockElement(block,idx);
            })
          }
        </Stack>
      }
    </Stack>
  );
}

