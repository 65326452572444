import React, {PropsWithChildren, useState} from 'react'
import {Box, Button, Card, CardActions, CardContent, CardMedia, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {BlockCard} from "@gk-lab/kirbyreact";
import {ArrowForwardIos} from '@mui/icons-material';
import {IDMScope} from "../types/Scope";
import {SxProps, Theme} from "@mui/system";

interface Props extends PropsWithChildren<any>{
  data:BlockCard,
  scope?:IDMScope,
  sx?:SxProps<Theme>,
}

export default function IDMCard (props:Props) {

  /* eslint-disable-next-line */
  const [hovered, setHovered] = useState<Boolean>(false);

  // const ref = useRef<HTMLDivElement>(null);

  return (
    <Card
      sx={{
        maxWidth: '100%',
        display: 'flex',
        height: "100%",
        backgroundColor: "common.white",
        flexDirection: {
          xs: 'column',
        },
        ...props.sx,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      {props.data.url && props.data.name && <CardMedia
        sx={{
          height: "0",
          width: "100%",
          paddingBottom: "80%",
          // mixBlendMode: !hovered ? "plus-lighter" : "unset",
          // maxHeight: {xs: "240px", md: `${maxHeight}px`},
          // filter: !hovered ? "grayscale(100%) contrast(150%)" : "none",
          // opacity: hovered ? 1 : 0.75,
          // transition: "all 0.4s ease-in-out",
          display: "block"
        }}
        // ref={ref}
        image={props.data.url}
        title={props.data.name}
      />}
      <Stack
        sx={{
          flexGrow: 1,
          backgroundColor: "common.white",
          px: 4
        }}
        direction={"column"}>
        <CardContent
          sx={{
            flexGrow: 1,
            textAlign: props.data.align ?? "left",
          }}
        >
          {props.data.heading &&
            <Typography
              className={"card-heading"} gutterBottom component="h3"
            >
              {props.data.heading}
            </Typography>
          }
          {props.data.subtitle &&
          <Typography
            className={"card-subtitle"} gutterBottom component="h4" dangerouslySetInnerHTML={{ __html: props.data.subtitle}}
          />
          }
        </CardContent>
        {props.data.action &&
        <CardActions sx={{
          display: 'block',
          pointerEvents: 'auto',
          width: "100%"
        }}>
          <Button
            component={Link}
            className={"card-action"}
            sx={{
              display: 'flex',
              m: 0,
              p: 0,
              // justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            to={`/${props.data.action}`}
            aria-label={props.data.cta}
          >
            <Stack
              direction={"row"}
               sx={{
                 width: "100%",
                 // justifyContent: "center"
               }}
            >
              <Box sx={{
                display: 'flex',
                width: {xs: "3rem", md: "3.5rem"},
                height: {xs: "3rem", md: "3.5rem"},
                minWidth: {xs: "3rem", md: "3.5rem"},
                minHeight: {xs: "3rem", md: "3.5rem"},
                maxWidth: {xs: "3rem", md: "3.5rem"},
                maxHeight: {xs: "3rem", md: "3.5rem"},
                justifyContent: "center",
                alignItems: "center",
                mr: 4,
                background: (theme) => theme.palette.primary.main
              }}>
                <ArrowForwardIos fontSize={"medium"} sx={{ color: "common.white" }}/>
              </Box>
              {props.data.cta && <Typography
                className={"card-cta"} gutterBottom component="span"
                sx={{
                  margin: "auto 0",
                  flexGrow: 1,
                  height: "fit-content",
                  p: 0,
                  textAlign: "left",
                }}
              >{props.data.cta}</Typography>}
            </Stack>

          </Button>
        </CardActions>
        }
      </Stack>
    </Card>
  )
}
