import React, {useMemo, useState} from "react";
import {useForm, Controller, UnpackNestedValue} from "react-hook-form";
import {BlockForm} from "../../types/Block";
import {Button, Box, Checkbox, FormGroup, Stack, TextField, Typography, CircularProgress} from "@mui/material";
import {getBlockElement} from "../../utils/sectionUtils";
import {KirbyProps} from "@gk-lab/kirbyreact";
import {IDMScope} from "../../types/Scope";
import {defaultPadding, primary, secondary} from "../../Theme";
/* eslint-disable */
interface Props extends KirbyProps {
  data:BlockForm
}

type FormValues = {
  Name:string,
  Mail:string,
  Tel:string,
  Message:string,
  Checkbox:boolean,
}

const defaultValues:FormValues = {
  Name: "",
  Mail: "",
  Tel: "",
  Message: "",
  Checkbox: false,
};


export default function KirbyForm (props:Props) {
  const { handleSubmit, /*reset, setValue,*/ control } = useForm({ defaultValues });
  const [data, setData] = useState<UnpackNestedValue<any>>(null);
  const [sending, setSending] = useState<boolean>(false);
  const [send, setSend] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  // console.log('default - KirbyForm: ', props.data)

  const [errors, setErrors] = useState<string[]>([]);

  const formUrl = props.data.action;

  function sendData (sendData:string):void {
    setDisabled(true);
    setSending(true);
    setSend(false);
    // console.log("Callback - onValidData: ", formUrl, sendData);
    if(sendData && formUrl) {
      fetch(formUrl,{
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        method: "POST",
        body:  sendData
      })
        .then( (response) => {
          setSending(false);
          setSend(true);
          return response.json() })
        .then( (json) => {
          //console.log("onValidData - php response: " + JSON.stringify(json));
          if(json) {
            //console.log('sendData - json is valid!!! result: ', json[0].result);
          }
          if(json && json[0] && Boolean(json[0].result)) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        });
    } else {
      setSend(false);
      setSuccess(false);
    }
  }

  const onSubmit = (data:FormValues) => {
    //console.log('default - onSubmit: ', data);
    const err = [];
    if(!data.Name || data.Name.length < 2) {
      err.push("Name");
    }
    if(!data.Message || data.Message.length < 2) {
      err.push("Message");
    }
    if(!data.Checkbox) {
      err.push("Checkbox");
    }

    if(!data.Mail || data.Mail.length < 2 || !data.Mail.includes("@")) {
      err.push("Mail");
    }

    setData(data);
    setErrors(err);

    if(err.length === 0) {
      //console.log('default - onSubmit: valid ', data);
      sendData(JSON.stringify(data));
    }
  }

  const {disclaimerCopy, successCopy, errorCopy, cta} = props.data;

  const buttonContent = useMemo(() => {
    // console.log('getButtonContent - getButtonContent: ', send, send, success);
    if(!send) {
      if(sending) {
        return <CircularProgress color={"secondary"} />
      }
      return <Button
        type={"submit"}
        variant={"contained"}
        disabled={disabled}
        aria-disabled={disabled}
      >
        {cta ?? "Senden"}
      </Button>
    }

    let copy;
    if(success) {
      copy =  Boolean(successCopy) ? successCopy : "Ihre Anfrage wurde erfolgreich versendet.";
    } else {
      copy = Boolean(errorCopy) ? errorCopy : "Leider ist ein Fehler aufgetreten. Bitte versuchen sie es später nocheinmal";
    }

/*    console.log('getButtonContent - getButtonContent: copy: ', copy);
    console.log('getButtonContent - getButtonContent: successCopy: ', successCopy);
    console.log('getButtonContent - getButtonContent: errorCopy: ', errorCopy);*/

    return <Typography
      sx={{
        textAlign: "center",
        width: "100%",
        fontSize: "1rem",
      }}
      variant='body1'
    >
      {copy}
    </Typography>

  }, [send, sending, success, successCopy, errorCopy, cta]);

  return (
    <form
      onSubmit={handleSubmit((data) => onSubmit(data))} className="form"
      noValidate autoComplete="off"
    >
      <Stack
      flexDirection={{xs: "column", md: "row"}}
      sx={{
        padding: defaultPadding,
        width: "100%",
        flexWrap: "wrap"
      }}
      >
        <Box sx={{width: {xs: "100%", lg: "40%"}, mb: 4, pr: 4}}>
          {props.data.infos && props.data.infos.map((block, idx) => {
            const component = getBlockElement(block, idx, IDMScope.FORM);
            return component;
          })}
        </Box>
        <Stack
          flexDirection={"row"}
          gap={4}
          sx={{
            flexWrap: "wrap",
            width: {xs: "100%", lg: "60%"},
            mt: {xs: 4, md: 12},
            mb: 4,
          }}
        >
          <Box sx={{width: {xs: "100%"}}}>
            <Controller
              render={({ field }) => <TextField
                id="name"
                label="Name*"
                variant="filled"
                sx={{width: "100%"}}
                helperText="Name, Vorname"
                error={errors.includes("Name")}
                disabled={disabled}
                aria-disabled={disabled}
                {...field}
              />}
              name="Name"
              control={control}
            />
          </Box>
          <Stack
            flexDirection={{xs: "column", sm: "row"}}
            gap={4}
            sx={{
              width: "100%",
            }}
          >
            <Box sx={{width: {xs: "100%", sm: "50%"}}}>
              <Controller
                render={({ field }) => <TextField
                  id="mail"
                  label="E-Mail"
                  variant="filled"
                  error={errors.includes("Mail")}
                  sx={{width: "100%"}}
                  disabled={disabled}
                  aria-disabled={disabled}
                  {...field}
                />}
                name="Mail"
                control={control}
              />
            </Box>
            <Box sx={{width: {xs: "100%", sm: "50%"}}}>
              <Controller
                render={({ field }) => <TextField
                  id="tel"
                  label="Telefonnummer"
                  variant="filled"
                  sx={{width: "100%"}}
                  error={errors.includes("Tel")}
                  disabled={disabled}
                  aria-disabled={disabled}
                  {...field}
                />}
                name="Tel"
                control={control}
              />
            </Box>
          </Stack>

          <Box sx={{width: {xs: "100%"}}}>
            <Controller
              render={({ field }) => <TextField
                id="message"
                label="Nachricht*"
                variant="filled"
                multiline={true}
                error={errors.includes("Message")}
                sx={{width: "100%"}}
                disabled={disabled}
                aria-disabled={disabled}
                {...field}
              />}
              name="Message"
              control={control}
            />
          </Box>
          <Box sx={{width: {xs: "100%"}}}>
            <FormGroup>
              <Controller
                name="Checkbox"
                control={control}
                render={({ field }) => (
                  <Stack direction={"row"}>
                    <Checkbox
                      id="disclaimer"
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      sx={{alignSelf: "flex-start"}}
                      color={errors.includes("Checkbox") ? "error" : "default"}
                      disabled={disabled}
                      aria-disabled={disabled}
                    ></Checkbox>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        textAlign: "left",
                        color: errors.includes("Checkbox") ? primary : secondary,
                      }}
                      variant='body2' component="span" style={{wordWrap: "break-word"}}
                      dangerouslySetInnerHTML={{ __html: disclaimerCopy ?? "Datenschutz-Bedingungen akzeptiert."}}
                    />
                  </Stack>
                )}
              />
            </FormGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center"
            }}
          >
            {buttonContent}
          </Box>
        </Stack>
      </Stack>
    </form>
  );
}