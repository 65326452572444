import React from 'react';

export default function Leaf() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="59.138" height="87.067" viewBox="0 0 59.138 87.067">
        <defs>
            <clipPath id="clipPath">
                <rect id="Rechteck_81" data-name="Rechteck 81" width="59.138" height="87.067" fill="#203f39"/>
            </clipPath>
        </defs>
        <g id="Gruppe_174" data-name="Gruppe 174">
            <path id="Pfad_47" data-name="Pfad 47"
                  d="M29.787,0a9.543,9.543,0,0,0,.64.748A126.189,126.189,0,0,1,52.28,27.663c3.024,5.124,5.566,10.488,6.454,16.426C60.507,55.952,56.418,65.531,46.972,72.8a27.556,27.556,0,0,1-14.247,5.5,5.3,5.3,0,0,0-.538.116v8.65H27.075V78.5c-1.813-.369-3.57-.577-5.233-1.09C10.17,73.809,2.983,66.043.515,54.053-.777,47.778.493,41.775,2.849,35.946c3.1-7.668,7.7-14.42,12.812-20.854A133.117,133.117,0,0,1,29.06.515,3.607,3.607,0,0,0,29.447,0ZM27,73.15a1.761,1.761,0,0,0,.059-.321c0-3.26.017-6.519-.017-9.779a1.527,1.527,0,0,0-.478-.917Q16.722,52.256,6.852,42.4c-.174-.174-.369-.329-.571-.509C1.53,56.432,12.22,71.933,27,73.15M53,41.868c-.176.139-.291.213-.386.308Q42.576,52.214,32.551,62.269a1.627,1.627,0,0,0-.365,1.047c-.028,3.032-.017,6.065-.015,9.1,0,.248.024.5.037.747C46.161,72.043,57.7,57.542,53,41.868M14.35,25.555a3,3,0,0,0-.288.358c-1.865,3.3-3.719,6.6-5.6,9.9a.857.857,0,0,0,.212,1.222q8.892,8.846,17.75,17.727c.166.165.355.307.559.482A1.2,1.2,0,0,0,27.059,55c0-5.442.011-10.885-.012-16.327a1.389,1.389,0,0,0-.412-.858Q20.7,31.845,14.737,25.9c-.114-.114-.24-.217-.387-.349m30.677-.2c-4.334,4.339-8.465,8.465-12.569,12.618a1.547,1.547,0,0,0-.277,1c-.017,5.159-.012,10.319-.012,15.478v.685L51.12,36.187,45.028,25.359M27,30.755V9.941l-.2-.115-9.48,11.251L27,30.755m5.2-.113,9.566-9.564A129.776,129.776,0,0,0,32.2,9.779Z"
                  transform="translate(0)" fill="#203f39"/>
            <path id="Pfad_48" data-name="Pfad 48"
                  d="M44.47,215.541C29.689,214.323,19,198.823,23.75,184.286c.2.18.4.334.571.508q9.863,9.859,19.714,19.73a1.528,1.528,0,0,1,.477.917c.034,3.259.02,6.519.017,9.779a1.734,1.734,0,0,1-.059.321"
                  transform="translate(-17.469 -142.391)" fill="#dff8eb"/>
            <path id="Pfad_49" data-name="Pfad 49"
                  d="M162.334,184.164c4.69,15.674-6.844,30.175-20.8,31.292-.013-.25-.037-.5-.037-.746,0-3.032-.014-6.065.014-9.1a1.629,1.629,0,0,1,.365-1.047q10.013-10.067,20.068-20.093c.095-.1.211-.169.386-.308"
                  transform="translate(-109.329 -142.296)" fill="#dff8eb"/>
            <path id="Pfad_50" data-name="Pfad 50"
                  d="M42.551,112.408c.147.132.273.234.387.349q5.955,5.951,11.9,11.913a1.389,1.389,0,0,1,.412.858c.023,5.442.015,10.885.012,16.327a1.2,1.2,0,0,1-.071.238c-.2-.175-.394-.316-.559-.482q-8.872-8.867-17.751-17.727a.857.857,0,0,1-.212-1.222c1.877-3.292,3.731-6.6,5.6-9.9a3.017,3.017,0,0,1,.288-.358"
                  transform="translate(-28.2 -86.853)" fill="#dff8eb"/>
            <path id="Pfad_51" data-name="Pfad 51"
                  d="M154.363,111.549l6.092,10.828-18.95,18.951v-.685c0-5.159-.005-10.319.012-15.478a1.545,1.545,0,0,1,.277-1c4.1-4.153,8.235-8.278,12.569-12.618"
                  transform="translate(-109.335 -86.19)" fill="#dff8eb"/>
            <path id="Pfad_52" data-name="Pfad 52" d="M85.847,64.15l-9.684-9.678,9.48-11.251.2.115Z"
                  transform="translate(-58.848 -33.395)" fill="#dff8eb"/>
            <path id="Pfad_53" data-name="Pfad 53"
                  d="M141.628,63.88V43.017a129.778,129.778,0,0,1,9.566,11.3l-9.566,9.564"
                  transform="translate(-109.43 -33.238)" fill="#dff8eb"/>
        </g>
    </svg>)
}
