import React, {useEffect, useState} from 'react';
import {
  AppStatus,
  useAppState,
  KirbyRouter, ScrollToTop, useKirbyData,
} from "@gk-lab/kirbyreact";
import {Box, Container, Fab, Typography} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {getSectionElement} from "./utils/sectionUtils";
import IDMAppHeader from "./components/IDMAppHeader";
import IDMNavigation from "./components/IDMNavigation";
import {NavigationType, useLocation, useNavigationType} from "react-router-dom";
import {accent, defaultPadding, primary, white} from "./Theme";
import StickyImage from "./components/StickyImage";

export function isValidSection(id:string):boolean {
  return ['default', 'project'].indexOf(id) >= 0;
}

const path:string =
  process && process?.env?.NODE_ENV === 'development'
    ? 'http://localhost/cms/'
    : '/cms/'

function App () {
  const {listedSections, state, sticky, hidemainnavigation} = useAppState();
  // eslint-disable-next-line
  const kirbyData = useKirbyData(path);
  // const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [scrollTarget, setScrollTarget] = useState<Node|undefined>(undefined);

  const [open, setOpen] = React.useState(false);

  const { pathname, hash } = useLocation();

  // TODO Check scrollTo
  const navigationType = useNavigationType();
  // console.log('App - navigationType: ', navigationType, navigationType === NavigationType.Push);

  useEffect(() => {
    const getMeTo:HTMLElement | null = hash ? document.getElementById(hash.replace("#", "").toLowerCase()) : null;
    if(scrollTarget) {
      (scrollTarget as Element).scrollTo(0, 0);
    }
    if (getMeTo){
      setTimeout(() => {
        getMeTo.scrollIntoView({behavior: 'smooth'});
      }, 500);
    }
  }, [pathname, hash, scrollTarget]);

  return (
      <Box sx={{
        height: "100%",
        maxHeight: "100vh",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "100%",
        gridTemplateRows: "90px 1fr",
        gridTemplateAreas: `'header' 'main'`,
        overflow: "hidden"
      }}>
          {state === AppStatus.LOADED && listedSections &&
            <>
              {hidemainnavigation === 'false' &&
                <IDMNavigation
                  open={open}
                  handleClose={() => setOpen(!open)}
                  handleClick={() => setOpen(false)}
                  mainItems={kirbyData.data?.navitems}
                  metaItems={kirbyData.data?.metaitems}
                  socials={kirbyData.data?.sociallinks}
                />
              }
              <Box
                component={"main"}
                sx={{gridArea: "main", overflowY: "auto"}}
                 ref={(node:Node) => {
                   if (node) {
                     setScrollTarget(node);
                   }
                 }}
              >
                <Container maxWidth={false} >
                  <div id={"back-to-top-anchor"}></div>
                  <KirbyRouter sections={listedSections} getSectionElement={getSectionElement}/>
                </Container>
              </Box>
              <IDMAppHeader
                sx={{
                  gridArea: "header",
                  maxHeight: "90px",
                  zIndex: 3000
                }}
                maxWidth={false}
                containerSx={{
                  maxWidth: "1920px"
                }}
                asOverlay={open}
                open={open}
                handleOpen={() => setOpen(true)}
                handleClose={() => setOpen(false)}
              >
                {scrollTarget && !open &&
                  <Container
                    maxWidth={false}
                    sx={{
                      maxWidth: "1920px",
                      position: "fixed",
                      bottom: 0,
                    }}
                  >
                    {sticky &&
                      <StickyImage data={sticky} sx={{
                        left: defaultPadding,
                        bottom: "10px",
                        ml: "-1rem"
                      }}
                      />
                    }
                    <ScrollToTop
                      target={scrollTarget}
                      boxSx={{
                        position: "absolute",
                        bottom: "1rem",
                        right: defaultPadding,
                        mr: "-1rem"
                      }}
                    >
                      <Fab
                        color="secondary" size="small" aria-label="scroll back to top"
                        sx={{
                          backgroundColor: accent,
                          borderRadius: 0,
                          "&:hover, &:focus": {
                            backgroundColor: primary,
                            color: white,
                          }
                        }}
                      >
                        <KeyboardArrowUpIcon/>
                      </Fab>
                    </ScrollToTop>
                  </Container>
                }
              </IDMAppHeader>
              {/*<KirbySnackbar
                data={{type: BlockType.SNACKBAR, text: "Disclaimer"}}
                handleClose={() => setShowDisclaimer(false)}
                open={showDisclaimer}
                muiProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" }
                }}
              />*/}
            </>
          }
          {state !== AppStatus.LOADED &&
            <Box p={4}>
              <Typography component={"p"} fontSize={"1rem"}>loading...</Typography>
            </Box>
          }
      </Box>
  )
}

export default App