import React from 'react';

export default function IDMArrowLeft() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="130" height="16" viewBox="0 0 130 16">
        <defs>
            <clipPath>
                <path d="M0,2.6H129.048V-13.644H0Z" transform="translate(0 13.644)" fill="none" stroke="#b32b42" strokeWidth="5"/>
            </clipPath>
        </defs>
        <g transform="translate(0 13.644)">
            <g transform="translate(0 -13.644)" clipPath="url(#clipPath)">
                <g transform="translate(2.622 8.119)">
                    <path d="M20.2,0H-106.222" transform="translate(106.222)" fill="none" stroke="#b32b42" strokeWidth="5"/>
                </g>
                <g transform="translate(2.622 1.307)">
                    <path d="M1.171,2.177-6.156-4.635l7.327-6.812" transform="translate(6.156 11.447)" fill="none" stroke="#b32b42" strokeWidth="5"/>
                </g>
            </g>
        </g>
    </svg>


}
