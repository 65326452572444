import {TypographyProps} from "@mui/material/Typography";

export enum IDMScope {
    MAIN = 'main',
    FOOTER = 'footer',
    HEADER = 'header',
    STAGE = 'stage',
    NAVIGATION = 'navigation',
    PROJECT = 'project',
    CARD = 'card',
    FORM = 'form',
    INFOCARD = 'infocard',
    INFOCARD_PRIMARY = 'infocard-primary',
    INFOCARD_SECONDARY = 'infocard-secondary',
    INFOCARD_ACCENT = 'infocard-accent',
}
function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export function getTypoVariant(scope:string| undefined):TypographyProps['variant'] | undefined {
    let result;
    if(scope) {
        enumKeys(IDMScope).forEach((key) => {
            if((IDMScope[key]) === scope) {
                result = IDMScope[key];
            }
        })
    }
    return result ?? undefined;
}