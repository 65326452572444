import React from 'react'
import {Stack} from "@mui/material";
import {BlockImageList} from "../types/Block";
import IDMHeadline from "./IDMHeadline";
import IDMText from "./IDMText";
import {Block} from "@gk-lab/kirbyreact";
import {getBlockElement} from "../utils/sectionUtils";
import {defaultPadding} from "../Theme";

interface Props {
  data:BlockImageList,
  scope?: string | undefined;
}

export default function ImageList (props:Props):JSX.Element {

  return (
    <Stack
      className={props.scope ?? "imagelist"}
      direction={"column"}
      flexWrap={"wrap"}
      // alignItems={"center"}
      px={defaultPadding}
      sx={{
        height: "fit-content",
        width: "100%",
        mb: 16,
        mt: 8,
      }}
      id={props.data.id}
    >
      {props.data.heading && <IDMHeadline data={props.data.heading} />}
      {props.data.text &&  <IDMText data={props.data.text} />}
      <Stack
        className={"container-elements"}
        direction={"row"}
        flexWrap={"wrap"}
        alignItems={"flex-start"}
        sx={{
          height: "fit-content",
          width: "100%",
        }}
      >
        {props.data.items.map((item, idx) => {
          return (
            <Stack
              direction={"row"}
              key={`list-element-${idx.toString()}`}
              sx={{
                width: {xs: "100%", sm: "50%", md: "33%", lg: "25%"},
              }}
            >
              {getBlockElement(item,idx, props.scope ?? "imagelist")}
            </Stack>
          )
        })}
        {props.data.footer &&
          <Stack
            className={"footer"}
            sx={{
              width: "100%",
              direction: "column",
              mt: 8
            }}
          >
            {
              props.data.footer && props.data.footer.map((block:Block, idx:number) => {
                return getBlockElement(block,idx);
              })
            }
          </Stack>
        }
      </Stack>
    </Stack>
  );
}

