import React, {useMemo, useState} from 'react'
import {BlockCard, BlockCards, KirbyCardGroup, KirbyProps} from "@gk-lab/kirbyreact";
import IDMCard from "./IDMCard";
import {Typography, Stack, Box} from "@mui/material";


interface Props extends KirbyProps {
  data:BlockCards,
}

export default function IDMCardGroup (props:Props) {

  const [activeTags, setActiveTags] = useState<string[]>([]);

  // console.log('default - IDMCardGroup: ', props.data)
  
  const tags:string[] | undefined = useMemo(() => {
    const myTags:string[] = [];
    if(props.data.filter && props.data.cards && props.data.cards.length > 0) {
      props.data.cards.forEach((card) => {
        if(card.tags) {
          card.tags.split(",").forEach((tag) => {
            if(!myTags.includes(tag.toLowerCase())) {
              myTags.push(tag);
            }
          })
        }
      })
      return myTags;
    }
    return undefined;
  }, [props.data.cards, props.data.filter]);

  function onTagClick(tagValue:string) {
    const tagIdx = activeTags.indexOf(tagValue.toLowerCase());
    if(tagIdx !== -1) {
      const tempTags = activeTags.filter((t) => t !== tagValue.toLowerCase());
      setActiveTags(tempTags);
    } else {
      setActiveTags([...activeTags, tagValue.toLowerCase()]);
    }
  }

  function getTag (tagValue:string):JSX.Element {
    const isActive:boolean = activeTags.includes(tagValue.toLowerCase());
    return <Box
      component={"li"}
      sx={{
        px: 1,
        cursor: "pointer"
      }}
      key={tagValue}
      onClick={() => onTagClick(tagValue.toLowerCase())}
    >
      <Typography
        component="span"
        gutterBottom={false}
        sx={{
          color: isActive ? "primary.main" : "secondary.main",
          fontSize: {xs: "1.25rem", md: "1.625rem"},
        }}
      >{`#${tagValue}`}</Typography>
    </Box>
  }

  function getCard (card:BlockCard):JSX.Element | null {
    if(activeTags && activeTags.length > 0) {
      if(card.tags) {
        const cardTags = card.tags.split(",");
        let found:boolean = false;
        cardTags.forEach((cardTag) => {
          if (activeTags.includes(cardTag) && !found) {
            found = true;
          }
        })
        if(found) {
          return <IDMCard data={card} />
        }
        return null;
      } else {
        return null;
      }
    }
    return <IDMCard data={card} />
  }

  function getActiveCards():BlockCard[] {
    if(props.data.cards && props.data.cards.length > 0 && activeTags && activeTags.length > 0) {
      const filteredCards:BlockCard[] = props.data.cards.filter((card) => {
        if(card.tags) {
          const cardTags = card.tags.split(",");
          let found:boolean = false;
          cardTags.forEach((cardTag) => {
            if (activeTags.includes(cardTag) && !found) {
              found = true;
            }
          })
          return found;
        }
        return false;
      });
      return filteredCards;
    }
    return props.data.cards ?? [];
  }

  return (
    <Box
      id={props.data?.id?.toLowerCase()}
      sx={{
        my: 8
      }}
      className={"card-group"}
    >
      {tags &&
        <Box
          sx={{
            textAlign: "center"
          }}
        >
          <Typography
            component="h5"
            sx={{
              my: 0,
              py: 0,
              mb: 2,
              fontWeight: "bold"
            }}
          >Filter</Typography>
          <Stack
            component={"ul"}
            direction={"row"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            sx={{
              listStyle: "none",
              px: {xs: "1rem", md: "4rem"},
              py: 0,
              my: 8
            }}
          >
            {tags.map((tag) => getTag(tag))}
          </Stack>
        </Box>
      }

      <KirbyCardGroup data={{
        ...props.data,
        cards: getActiveCards()
      }} getCard={getCard} />
    </Box>
  )
}
