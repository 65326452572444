import React, {PropsWithChildren, useMemo} from 'react'
import {BlockText, KirbyText} from "@gk-lab/kirbyreact";
import {getTypoVariant} from "../types/Scope";
import {cssWrapper, getResponsiveValues} from "../utils/utils";

interface Props extends PropsWithChildren<any>{
  data:BlockText,
  scope?:string,
}

export default function IDMText (props:Props) {

  const className = useMemo(() => {
    const cl = ["copy"];
    if(props.data.align) cl.push(props.data.align);
    if(props.data.variant) cl.push(props.data.variant);
    if(props.scope) cl.push(props.scope);
    return cl.join(" ");
  }, [props.scope, props.data.align,props.data.variant]);

  return cssWrapper(
      <KirbyText
        data={props.data}
        variant={getTypoVariant(props.scope) ?? "body1"}
        sx={{
          ...(props.data.maxwidth && {maxWidth: getResponsiveValues(props.data.maxwidth)}),
          ...props.sx,
        }}
        className={className}
      />
    , props.data.css)
}
