import React, {PropsWithChildren} from 'react'
import {Stack} from "@mui/material";
import {BlockSimpleCard} from "../types/Block";
import {Block} from "@gk-lab/kirbyreact";
import {getBlockElement} from "../utils/sectionUtils";

interface Props extends PropsWithChildren<any>{
  data:BlockSimpleCard;
  scope?: string | undefined;
}

export default function SimpleCard (props:Props) {
  const {blocks} = props.data;
  return (
    <Stack flexDirection={"column"}
      sx={{
        width: "100%"
      }}
    >
      {blocks &&
      blocks.map((block:Block, idx:number) => {
        return getBlockElement(block,idx);
      })
      }
    </Stack>
  )
}
